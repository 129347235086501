<template>
  <div class="daily-sett">
    <el-card>
      <div slot="header" class="clearfix picker-box">
        <span>持仓明细</span>
        <ExcelDownLoad class="download-btn" filename="持仓明细" api="dataHolddetails" :size="1000" />
        <date-range style="float: right" v-on:change="loadData(page)"></date-range>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="rq" label="日期" width="110"> </el-table-column>
        <el-table-column prop="pzbm" label="品种编码" width="110">
        </el-table-column>
        <el-table-column prop="pzhy" label="品种合约" width="110">
        </el-table-column>
        <el-table-column prop="cjlsh" label="成交流水号" width="140">
        </el-table-column>
        <el-table-column prop="mmfxbz" label="买卖方向标志" width="110">
        </el-table-column>
        <el-table-column prop="tjtbbz" label="投机套保标志" width="110">
        </el-table-column>
        <el-table-column prop="ccsl" label="持仓数量" width="110">
        </el-table-column>
        <el-table-column prop="kcj" label="开仓价" width="110">
        </el-table-column>
        <el-table-column prop="zjsj" label="昨结算价" width="110">
        </el-table-column>
        <el-table-column prop="jjsj" label="今结算价" width="110">
        </el-table-column>
        <el-table-column prop="zrccyk" label="持仓盈亏(逐日盯市)" width="140">
        </el-table-column>
        <el-table-column prop="zbccyk" label="持仓盈亏(逐笔对冲)" width="140">
        </el-table-column>
        <el-table-column prop="jybm" label="交易编码" width="110">
        </el-table-column>
        <el-table-column prop="jybzj" label="交易保证金" width="110">
        </el-table-column>
        <el-table-column prop="cjrq" label="成交日期" width="110">
        </el-table-column>
      </el-table>
      <el-pagination
              background
              layout="prev, pager, next, sizes"
              @size-change="sizeChange"
              @current-change="loadData"
              :page-sizes="[10, 20, 30, 40, 50]"
              :page-size="10"
              :total="total"
      >
      </el-pagination>
      <VarietyPicker v-show="tableData.length>0" class="custom" v-on:varietyChange="loadData(page)" />
    </el-card>
  </div>
</template>

<script>
  import DateRange from '@/components/DateRange.vue'
  import VarietyPicker from '@/components/VarietyPicker.vue'
  import { Loading } from "element-ui";
  import {apiAnalysisData} from "../../api/analysis";
  import ExcelDownLoad from "../../components/ExcelDownLoad";

  export default {
    name: "TradeDetail",
    components: {
      DateRange,
      VarietyPicker,
      ExcelDownLoad
    },
    data() {
      return {
        size: 10,
        page: 1,
        total: 0,
        date: "",
        tableData: [],
        value: "",
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [
            {
              text: "今天",
              onClick(picker) {
                picker.$emit("pick", new Date());
              },
            },
            {
              text: "昨天",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                picker.$emit("pick", date);
              },
            },
            {
              text: "一周前",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", date);
              },
            },
          ],
        },
      };
    },
    methods: {
      sizeChange(size) {
        this.size = size;
        this.loadData(this.page);
      },
      async loadData (page) {
        this.page = page;
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date,
          page: this.page,
          size: this.size,
          v_code: this.$store.getters.checkedVarieties.join(',')
        }
        let loadingInstance = Loading.service({ target: ".el-table" });
        let res = await apiAnalysisData( 'dataHolddetails', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          this.total = res.data.total;
          resData.forEach(item => {
            delete item.id
            delete item.account_id
            delete item.created_at
            delete item.updated_at
          })
          this.tableData = resData
          loadingInstance.close();
        }
      },
      dateChange(picker) {
        if (picker) {
          let Y = picker.getFullYear().toString();
          let M = (picker.getMonth() + 1 < 10
                          ? "0" + (picker.getMonth() + 1)
                          : picker.getMonth() + 1
          ).toString();
          let D = picker.getDate().toString();
          let date = Y + "-" + M + "-" + D;
          this.date = date;
        } else {
          this.date = "";
        }
        this.loadData(1);
      },
    },
    async mounted () {
      await this.$store.commit('SET_CHECK_ALL', true)
      await this.$store.dispatch('setAllVarieties')
      this.loadData(1);
    },
  };
</script>

<style lang="scss">
  .daily-sett {
    .custom {
      margin-top: 20px;
    }
    .picker-box {
      position: relative;
      .download-btn {
        width: fit-content;
        height: fit-content;
        border-color: #e78051;
        background: #e78051;
        color: #FFF;
        position: absolute;
        right: 45px;
        top: 0;
      }
      .date-picker {
        width: 140px;
        position: absolute;
        right: -4px;
        top: -4px;
        .el-input__inner {
          width: 140px;
          height: 28px;
        }
        .el-input__icon {
          line-height: 28px;
        }
      }
    }
    .el-card {
      border: 0;

      .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }
    }

    .cell {
      font-size: 12px;
    }

    .el-pagination {
      margin-top: 20px;
    }
  }
</style>
